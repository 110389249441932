import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppStateConstants} from 'app/globals';
import {Observable} from 'rxjs';
import {AppStateService} from '../app.service';

@Injectable({providedIn: 'root'})
export class ApiAnonymousService {
  private readonly serverUrl: string;

  constructor(private _appState: AppStateService, private httpClient: HttpClient) {
    this.serverUrl = this._appState.get(AppStateConstants.lightSailServerURL);
  }

  get<T>(path: string): Observable<T> {
    return this.httpClient.get<T>(this.getUrl(path));
  }

  put<T>(path: string, content: any): Observable<T> {
    return this.httpClient.put<T>(this.getUrl(path), content);
  }

  post<T = any>(path: string, content: any) {
    return this.httpClient.post<T>(this.getUrl(path), content, {
      observe: 'response',
    });
  }

  postWithTextResponse<T = any>(path: string, content: any) {
    return this.httpClient.post<T>(this.getUrl(path), content, {
      observe: 'body',
      responseType: 'text' as any,
    });
  }

  private getUrl(path: string): string {
    return this.serverUrl + path + (path.includes('?') ? '&' : '?') + 'cb=' + Date.now();
  }
}
